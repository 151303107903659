<template>
  <main id="main-container">
    <div
      class="bg-image"
      style="background-image: url('/static/media/photos/photo34@2x.jpg')"
    ></div>
  </main>
</template>

<script>
import { setRoutes } from "@/router";
import { qqLogin, qqBind } from "@/api/login";
export default {
  name: "QQ",
  data() {
    return {
      data: {
        token: null,
      },
    };
  },
  created() {
    if (this.$route.query.token) {
      this.data.token = this.$route.query.token;
      this.qq_Login();
    } else {
      this.data.token = this.$route.query.bind;
      this.qq_Bind();
    }
  },
  methods: {
    qq_Login() {
      qqLogin(this.data)
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res)); // 存储用户信息到浏览器
          localStorage.setItem("menus", JSON.stringify(res.menus)); // 存储menus信息到浏览器
          // 动态设置当前用户的路由
          setRoutes();
          this.$message.success("qq登录成功");

          setTimeout(() => {
            window.location.href = "home";
          }, 1000);
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    qq_Bind() {
      qqBind(this.data)
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res)); // 存储用户信息到浏览器
          localStorage.setItem("menus", JSON.stringify(res.menus)); // 存储menus信息到浏览器
          // 动态设置当前用户的路由
          setRoutes();
          this.$message.success("qq绑定成功");

          setTimeout(() => {
            window.location.href = "user";
          }, 1000);
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
  },
};
</script>

<style>
@media screen and (max-width: 500px) {
  .el-message-box {
    width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .el-message {
    min-width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .el-dialog__wrapper .el-dialog {
    width: 300px !important;
  }

  .el-dialog__body {
    padding: 10px 20px !important;
  }

  .el-form-item__label {
    width: 68px !important;
  }

  .el-select,
  .el-input {
    width: 180px !important;
  }
}
</style>
